 
  .avatar {
    float: left;
    position: absolute;
    left: 1vmin;
    margin: 1vmin 1vmin 1vmin 0vmin;
    border-radius: 100%;
    max-height: 16vmin;
    max-width: 16vmin;
  }

  .navbar {
    position: fixed;
    top: 0px;
    background-color: #00000031;
    width: 100%;
    max-height: 20vmin;
    min-height: 18vmin;
    font-size: calc(10px + 2vmin);
    color: white;
    z-index: 10000;
    list-style: none;
    text-align: center;
  }

  .navbar div {
    
  }

  .p1 {
    font-size: calc(10px + 2vmin);
    color: white;
    margin-top: 6vmin;
  }
  
