 
  .logo {
    vertical-align: middle;
    position: relative;
    border-radius: 100%;
    max-height: calc(10px + 20vmin);
    margin: 4vmin;
  }

  .projects {
    grid-gap: 2vmin;
    display: grid;
    grid-template-columns: repeat(auto-fit);
    width: 125vmin;
    margin: auto;
    font-size: calc(10px + 1.5vmin);
    padding: 2vmin;
  }

  .projects-all {
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    vertical-align: middle;
    margin: auto;
  }

  .click {
    text-align: left;
    padding-top: 1.75vmin;
  }

  .container {
    background-color: #0000004d;
    text-align: center;
    padding: 1vmin;  
    display: flex;
  }

  .desc {
    text-align: left;
    padding: 1vmin;
  }

  .p1 {
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
